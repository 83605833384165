import React, { Component } from "react"
import { DEFAULT } from "../../../config"
import { connect } from "react-redux"

import logo from "../../../logo.png"

import notImage from "../../../media/images/sinimagen.jpg"

import { handleFetchMediaByCodes, handleSetLoading } from "../../../actions/mediaAction"


import {
    Icon,
    Grid,
    Image,
    Card,
    Button,
    GridColumn,
    Header,
    Dimmer,
    Loader,
    Label,
    List
} from "semantic-ui-react"

import ModalImage from "react-modal-image"

class MediaPrint extends Component {
    constructor(props) {
        super(props);
        this.state = {
            media: []
        };
        this.getCardMedium = this.getCardMedium.bind(this);
        this.renderMediaDescription = this.renderMediaDescription.bind(this);
        this.printPage = this.printPage.bind(this);
    }

    componentWillMount() {
        
        const codes = window.location.search.substr(5).split(",");
        this.props.dispatch(handleFetchMediaByCodes(codes))
        // ids.forEach(id => {
        //     return this.props.media.mediaList.forEach(medium => {
        //         if (medium.full_code === id) media.push(medium);
        //     });
        // });
        setTimeout(() => {
            this.props.dispatch(handleSetLoading(false))
        }, 2000)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.media.mediaList.lenght !== this.props.media.mediaFiltered.length) {
            console.log(this.props.media.mediaList)
        }
    }



    getCardMedium(medium, index) {
        
        let favoritePicture = medium.pictures[0]
        
        const urlPicture = favoritePicture
            ? `${DEFAULT.storage}${favoritePicture.path}`
            : notImage
            
        const streetView =
            medium.streetview != null ? (
                <a
                    href={medium.streetview}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon name="street view" size="large"/>
                </a>
            ) : (
                ""
            );
        let separator = <></>;
        if ((index + 1) % 3 === 0) {
            separator = (
                <>
                    <br /> <br /> <br /> <br /> <br /> <br />
                </>
            );
        }
        return (
            <div key={`favorite_${index}`}>
                <Grid.Column>
                    <Card centered>
                        <ModalImage 
                            small={urlPicture}
                            large={urlPicture}
                            // wrapped 
                            // ui={false}
                            
                        />
                        <Card.Content textAlign="left">
                            <Card.Header as="h6">{`${medium.city.name}, ${medium.state.name}.`}</Card.Header>
                            <Card.Meta>
                                <span className="date">Especificaciones</span>
                            </Card.Meta>
                            <Card.Description>
                                <b>Dirección:</b>{" "}
                                {`${medium.address}, C.P. ${medium.postal_code}`}
                                <br />
                                <b>Tipo:</b> {medium.type.name}
                                <br />
                                <b>Medidas:</b>{" "}
                                {`${medium.width} x ${medium.height} mts`}
                                <br />
                                <b>Cardinalidad:</b> {medium.cardinality}
                                <br />
                                <b>Vista:</b> {medium.view === "Crossed" ? "Curzada" : "Natural"}
                                <br />
                                <b>Iluminación:</b>{" "}
                                {medium.illuminated ? "Sí" : "No"}
                                <br />
                                <b>Nivel de Impacto:</b> {medium.impact_level}
                                <br />
                                <b>Nivel Socioeconómico:</b>{" "}
                                {medium.socioeconomic_level}
                                <br />
                                <b>Impactos Mensuales:</b>{" "}
                                {medium.monthly_impacts} / mes
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            {streetView}
                            <label>
                                <Icon name="map marker" />
                                {`Lat: ${medium.lattitude}`}{" "}
                                {`Long: ${medium.longitude}`}
                            </label>
                        </Card.Content>
                    </Card>
                </Grid.Column>
                {separator}
            </div>
        );
    }

    renderMediaDescription() {
        if (this.props.media.mediaList) {
            return (
                <Grid columns={3} centered>
                    {this.props.media.mediaList.map((medium, index) =>
                        this.getCardMedium(medium, index)
                    )}
                </Grid>
            );
        } else return <></>;
    }

    printPage() {
        // const html = ReactDOMServer.renderToStaticMarkup(
        //     <div>
        //         <br/>
        //         <Grid columns={1} centered>
        //         <Grid.Column textAlign='center'>
        //             <Image src={logo} size='small' centered style={{width:"200px", height: "auto"}}/>
        //         </Grid.Column>
        //         <Grid.Column textAlign='center'>
        //             <Header as='h3'>Comparación</Header>
        //         </Grid.Column>
        //         </Grid>
        //         {this.renderMediaDescription()}
        //     </div>
        // )
        // const html = document.getElementById("pinter");
        // console.log(html);
        // var doc = new jsPDF("p", "pt", "a4", true);
        
        const ids = window.location.search.substr(5).split(",");
        let media = [];
        if (this.props.media.mediaList) {
            ids.forEach(id => {
                return this.props.media.mediaList.forEach(medium => {
                    if (medium.full_code === id) media.push(medium);
                });
            });
        }

        // let image = `${DEFAULT.sistema}${media[0].pictures[0].path.replace(
        //     "public",
        //     "storage"
        // )}`
        // image = image.replace(/\s/g, "%20")
        // html2canvas(document.querySelector("#pinter")).then(canvas => {
        //     console.log(canvas)
        //     var imgData = canvas.toDataURL(
        //         'image/png');
        //     var doc = new jsPDF('p', 'pt', 'a4', true)
        //     doc.addImage(imgData, 'PNG', 10, 10)
        //     doc.save('sample-file.pdf')
        //     console.log(imgData)
        // })
        // const canvas = document.createElement("canvas")
        // const ctx = canvas.getContext("2d")
        // Set width and height
        // let img = document.createElement("img")
        // img.src = image
        // img.crossOrigin = "anonymous"
        // img.width = 250
        // img.height = 250
        // canvas.width = img.width
        // canvas.height = img.height
        // ctx.drawImage( img, 0, 0 )
        // const imgCanvas = canvas.toDataURL()
        // console.log(imgCanvas)
        // html.append(img)
        // html.append(canvas)
        // doc.addImage(imgCanvas, "JPEG", 15, 40, 180, 180)
        // canvas.width = img.width
        // canvas.height = img.height
        // // Draw the image
        // console.log(img)
        // doc.text(media[0].city.name, 35, 25)
        // doc.save("thisMotion.pdf");
        window.print()

    }

    render() {
        return (
            <div id="pinter">
                <Dimmer active={this.props.media.loading} page>
                    <Loader indeterminate>Cargando</Loader>
                </Dimmer>
                <br />
                <Grid columns={1} centered>
                    <Grid.Column textAlign="center">
                        <Image src={logo} size="small" centered />
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                        <Header as="h3">Comparación</Header>
                    </Grid.Column>
                    <GridColumn textAlign="center">
                        <Label basic color='orange' className="no-print" size="large">
                            Para descargar en PDF presiona el bóton imprimir y en el campo de destino selecciona la opción guardar en pdf
                        </Label>
                    </GridColumn>
                    <Grid.Column textAlign="center" className="no-print">
                        <Button
                            content="Descargar cotización"
                            icon="print"
                            labelPosition="right"
                            color="orange"
                            onClick={this.printPage}
                        />
                    </Grid.Column>
                </Grid>
                {this.renderMediaDescription()}
                <Grid columns={1} centered>
                    <Grid.Column>
                        <Header as="h5" textAlign="center">
                            CONTACTANOS:
                        </Header>
                        <List
                            verticalAlign="top"
                            style={{ textAlign: "center" }}>
                            <List.Item>
                                <List.Content>
                                    <List.Header
                                        href={`tel:+52${this.props.media.currentExecutive.phone}`}
                                        as="strong">
                                        {/* {`${this.props.media.currentExecutive.name}`} */}
                                    </List.Header>
                                    <List.Description>
                                        <p>
                                            {/* <strong>{this.props.media.currentExecutive.phoneFormatted}</strong><br/>
                                            <strong>Oficina: {this.props.media.currentExecutive.officePhoneNumberFormatted}</strong><br/><br/> */}
                                            {/* <strong>{this.props.media.currentExecutive.email}</strong><br/><br/> */}
                                        </p>
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <GridColumn textAlign="center" className="no-print">
                        <Button
                            content="Descargar cotización"
                            icon="print"
                            labelPosition="right"
                            color="orange"
                            onClick={this.printPage}
                        />
                    </GridColumn>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(MediaPrint);
