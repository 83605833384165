export default {
    'Alto Paraguay': ['Fuerte Olimpo'],
    'Alto Paraná': ['Ciudad del Este'],
    'Amambay': ['Pedro Juan Caballero'],
    'Boquerón': ['Filadelfia'],
    'Caaguazú': ['Coronel Oviedo'],
    'Caazapá': ['Caazapá'],
    'Canindeyú': ['Salto del Guairá'],
    'Central': ['Areguá'],
    'Concepción': ['Concepción'],
    'Cordillera': ['Caacupé'],
    'Guairá': ['Villarrica'],
    'Itapúa': ['Encarnación'],
    'Misiones': ['San Juan Bautista'],
    'Ñeembucú': ['Pilar'],
    'Paraguarí': ['Paraguarí'],
    'Presidente Hayes': ['Villa Hayes'],
    'San Pedro': ['San Pedro de Ycuamandiyú'],
    'Asunción': ['Asunción']
}  