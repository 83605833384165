export default {
    "Chuquisaca": [
        "Samuel Oropeza",
        "Juana Azurduy de Padilla",
        "Jaime Zudáñez",
        "Tomina",
        "Hernando Siles",
        "Yamparáez",
        "Nor Cinti",
        "Belisario Boeto",
        "Sud Cinti",
        "Luis Calvo"
    ],
    "La Paz": [
        "Murillo",
        "Omasuyos",
        "Pacajes",
        "Camacho",
        "Muñecas",
        "Larecaja",
        "Franz Tamayo",
        "Ingavi",
        "José Ramón Loayza",
        "Inquisivi",
        "Sud Yungas",
        "Los Andes",
        "Aroma",
        "Nor Yungas",
        "Iturralde",
        "Bautista Saavedra",
        "Manco Kapac",
        "Gualberto Villarroel",
        "General José Manuel Pando",
        "Caranavi"
    ],
    "Cochabamba": [
        "Cercado",
        "Campero",
        "Ayopaya",
        "Esteban Arze",
        "Arani",
        "Arque",
        "Capinota",
        "Germán Jordán",
        "Quillacollo",
        "Chapare",
        "Tapacarí",
        "José Carrasco",
        "Mizque",
        "Punata",
        "Bolívar",
        "Tiraque"
    ],
    "Oruro": [
        "Cercado",
        "Eduardo Abaroa",
        "Carangas",
        "Sajama",
        "Litoral de Atacama",
        "Poopó",
        "Pantaleón Dalence",
        "Ladislao Cabrera",
        "Sabaya",
        "Saucarí",
        "Tomás Barrón",
        "Sud Carangas",
        "San Pedro de Totora",
        "Sebastián Pagador",
        "Mejillones",
        "Nor Carangas"
    ],
    "Potosí": [
        "Tomás Frías",
        "Rafael Bustillo",
        "Cornelio Saavedra",
        "Chayanta",
        "Charcas",
        "Nor Chichas",
        "Alonso de Ibáñez",
        "Sud Chichas",
        "Nor Lípez",
        "Sud Lípez",
        "José María Linares",
        "Antonio Quijarro",
        "Bernardino Bilbao",
        "Daniel Campos",
        "Modesto Omiste",
        "Enrique Baldivieso"
    ],
    "Tarija": [
        "Cercado",
        "Aniceto Arce",
        "Gran Chaco",
        "José María Avilés",
        "Eustaquio Méndez",
        "Burdet O Connor"
    ],
    "Santa Cruz": [
        "Andrés Ibáñez",
        "Warnes",
        "Velasco",
        "Ichilo",
        "Chiquitos",
        "Sara",
        "Cordillera",
        "Vallegrande",
        "Florida",
        "Obispo Santistevan",
        "Ñuflo de Chaves",
        "Ángel Sandóval",
        "Caballero",
        "Germán Busch",
        "Guarayos"
    ],
    "Beni": [
        "Cercado",
        "Vaca Díez",
        "General José Ballivián Segurola",
        "Yacuma",
        "Moxos",
        "Marbán",
        "Mamoré",
        "Iténez"
    ],
    "Pando": [
        "Nicolás Suárez",
        "Manuripi",
        "Madre de Dios",
        "Abuná",
        "General Federico Román"
    ]
}