const executives = 
    [{
        name: "BÁRBARA",
        position: "Asesor de ventas",
        phoneFormatted: "(443)-146-7515",
        phone: "4431467515",
        email: "barbara.reyes@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "F"
    }, {
        name: "KARINA",
        position: "Asesor de ventas",
        phoneFormatted: "(417) 104-6431",
        phone: "4171046431",
        email: "karina.caballero@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "F"
    }, {
        name: "KEVIN",
        position: "Asesor de ventas",
        phoneFormatted: "(443) 616-7468",
        phone: "4436167468",
        email: "kevin.rodriguez@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "M"
    }, {
        name: "KAREN",
        position: "Asesor de ventas",
        phoneFormatted: "(443)-471-9025",
        phone: "4434719025",
        email: "karen.castellanos@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "F"
    }, {
        name: "LUIS",
        position: "Asesor de ventas",
        phoneFormatted: "(443) 507-0890",
        phone: "4435070890",
        email: "luis.lemus@naranti.com.mx",
        officePhoneNumberFormatted: "(443)-338-2109",
        officePhoneNumber: "4433382109",
        gender: "M"
    }]
const currentExecutive = executives[Math.floor(Math.random() * executives.length)]
const initialState = {
    media: {
        map: null,
        filters: [],
        mediaList: [],
        mediaFiltered: [],
        markersList: [],
        mapCircleList: [],
        pointsOfInterest: {},
        markerClusterer: null,
        favoriteMediaList: [],
        showMediaModal: false,
        showContactModal: false,
        currentMedium: null,
        executives: executives,
        currentExecutive: currentExecutive,
        loading: true,
        country: null,
        viewOnlyFavoriteList: false
    },
}

export default initialState;