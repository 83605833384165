import React from 'react';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ToastContainer } from 'react-toastify'
import Main from './components/applications/map/Main'
import MediaPrint from './components/applications/map/MediaPrint'
import MediaDetails from './components/applications/map/MediaDetails'


function App() {
  return (
    <Router basename={"/ooh"}>
      <div>
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Map</Link>
            </li>
          </ul>
        </nav> */}

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/:country/ooh/" component={Main} />
          <Route exact path="/:country" component={Main} />
          <Route path="/:country/printMedia" component={MediaPrint}/>
          <Route path="/:country/ooh/printMedia" component={MediaPrint}/>
          <Route path="/:country/ooh/:code" component={MediaDetails}/>
        </Switch>
        <ToastContainer/>
      </div>
    </Router>
  );
}

export default App;
