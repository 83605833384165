import React, { Component } from "react"
import { DEFAULT } from "../../../config"
import { connect } from "react-redux"

import { Map, Marker, GoogleApiWrapper } from "google-maps-react"
import Markers from "../../../media/Images"
import logo from "../../../logo.png"

import notImage from "../../../media/images/sinimagen.jpg"

import { handleFetchMedium, handleSetLoading } from "../../../actions/mediaAction"


import {
    Icon,
    Grid,
    Image,
    Card,
    Button,
    GridColumn,
    Header,
    Dimmer,
    Loader,
    Label,
    List,
    Container
} from "semantic-ui-react"

import ModalImage from "react-modal-image"

import "./MediaDetails.css"

class MediaDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            medium: null
        };
        this.getCardMedium = this.getCardMedium.bind(this);
        this.renderMediaDescription = this.renderMediaDescription.bind(this);
        this.printPage = this.printPage.bind(this);
        this.getMarker = this.getMarker.bind(this);
    }

    async componentWillMount () {
        const res = await this.props.dispatch(handleFetchMedium(this.props.match.params.code))
        this.setState({medium: res})
        setTimeout(() => {
            this.props.dispatch(handleSetLoading(false))
        }, 2000)
    }

    getMarker(medium, index) {
        const type = medium.type.name
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
        return (
            <Marker
                key={`medium_${index}`}
                position={{
                    lat: medium.lattitude,
                    lng: medium.longitude,
                }}
                icon={{
                    url: Markers[type],
                    anchor: new this.props.google.maps.Point(16, 16),
                    scaledSize: new this.props.google.maps.Size(32, 32),
                }}
                onClick={(marker) => {
                    let panorama = new this.props.google.maps.StreetViewPanorama(
                        this.refs.map_fav.mapRef.current,
                        {
                            position: marker.position,
                            enableCloseButton: true,
                            pov: {
                                heading: 135,
                                pitch: 20
                            }
                        }
                    )
                    panorama.setVisible(true);
                }}
                name={type}
            />
        )
    }



    getCardMedium(medium, index) {
        let favoritePicture = medium.pictures
        
        favoritePicture = favoritePicture
            ? favoritePicture
            : medium.pictures[0];

        favoritePicture = medium.pictures[0]
        const urlPicture = favoritePicture
            ? `${DEFAULT.storage}${favoritePicture.path}`
            : notImage
        
        const streetView =
            medium.streetview != null ? (
                <a
                    href={medium.streetview}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon name="street view" size="large"/>
                </a>
            ) : (
                ""
            );
        let separator = <></>;
        if ((index + 1) % 3 === 0) {
            separator = (
                <>
                    <br /> <br /> <br /> <br /> <br /> <br />
                </>
            );
        }
        return (
            <Grid container centered columns={2} className="custom-container">
                <Grid.Column computer={12} color="orange">
                    <Grid.Row>
                        <Header as="h3" inverted className="custom-header" >{medium.full_code}</Header>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column computer={12}>
                    <Image src={urlPicture} fluid />
                </Grid.Column>
                <Grid.Column computer={12} className="custom-header-gray">
                    <Grid.Row>
                        <Header as="h1">{`${medium.city.name}, ${medium.state.name}.`}</Header>
                    </Grid.Row>
                </Grid.Column>
                <Grid.Column computer={6} mobile={12} style={{height: "300px"}} centered>
                    <Map
                        ref="map_fav"
                        google={window.google}
                        initialCenter={{
                            lat: medium.lattitude,
                            lng: medium.longitude,
                        }}
                        rotateControl={false}
                        mapTypeControl={false}
                        panControl={false}
                        fullscreenControl={false}
                        // streetViewControl={false}
                        zoom={16}
                        style={{
                            height: "100%",
                            width: "85%",
                        }}>
                        {this.getMarker(medium, "fav_0")}
                    </Map>
                </Grid.Column>
                <Grid.Column computer={6} mobile={12}>
                    <Grid.Row className="custom-header-color" >
                        <Header as="h4">Especificaciones</Header>
                    </Grid.Row>
                    <Grid.Row>
                        <b>Dirección:</b>{" "}
                        <span>{`${medium.address}, C.P. ${medium.postal_code}`}</span>
                    </Grid.Row>
                    <Grid.Row>
                        <b>Tipo:</b> {medium.type.name}
                    </Grid.Row>
                    <Grid.Row>
                        <b>Medidas:</b>{" "}
                        {`${medium.width} x ${medium.height} mts`}
                    </Grid.Row>
                    <Grid.Row>
                        <b>Cardinalidad:</b> {medium.cardinality}
                    </Grid.Row>
                    <Grid.Row>
                        <b>Vista:</b> {medium.view === "Crossed" ? "Curzada" : "Natural"}
                    </Grid.Row>
                    <Grid.Row>
                        <b>Iluminación:</b>{" "}
                        {medium.illuminated ? "Sí" : "No"}
                    </Grid.Row>
                    <Grid.Row>
                        <b>Nivel de Impacto:</b> {medium.impact_level}
                    </Grid.Row>
                    <Grid.Row>
                        <b>Nivel Socioeconómico:</b>{" "}
                        {medium.socioeconomic_level}
                    </Grid.Row>
                    <Grid.Row>
                        <b>Impactos Mensuales:</b>{" "}
                        {medium.monthly_impacts} / mes
                    </Grid.Row>
                </Grid.Column>
            </Grid>
        );
    }

    renderMediaDescription() {
        const ids = window.location.search.substr(5).split(",");
        if (this.props.media.mediaList) {
            let media = [];
            ids.forEach(id => {
                return this.props.media.mediaList.forEach(medium => {
                    if (medium.full_code === id) media.push(medium);
                });
            });
            return (
                this.state.medium != null && this.getCardMedium(this.state.medium)
            );
        } else return <></>;
    }

    printPage() {
        // const html = ReactDOMServer.renderToStaticMarkup(
        //     <div>
        //         <br/>
        //         <Grid columns={1} centered>
        //         <Grid.Column textAlign='center'>
        //             <Image src={logo} size='small' centered style={{width:"200px", height: "auto"}}/>
        //         </Grid.Column>
        //         <Grid.Column textAlign='center'>
        //             <Header as='h3'>Comparación</Header>
        //         </Grid.Column>
        //         </Grid>
        //         {this.renderMediaDescription()}
        //     </div>
        // )
        // const html = document.getElementById("pinter");
        // console.log(html);
        // var doc = new jsPDF("p", "pt", "a4", true);
        
        const ids = window.location.search.substr(5).split(",");
        let media = [];
        if (this.props.media.mediaList) {
            ids.forEach(id => {
                return this.props.media.mediaList.forEach(medium => {
                    if (medium.full_code === id) media.push(medium);
                });
            });
        }

        // let image = `${DEFAULT.sistema}${media[0].pictures[0].path.replace(
        //     "public",
        //     "storage"
        // )}`
        // image = image.replace(/\s/g, "%20")
        // html2canvas(document.querySelector("#pinter")).then(canvas => {
        //     console.log(canvas)
        //     var imgData = canvas.toDataURL(
        //         'image/png');
        //     var doc = new jsPDF('p', 'pt', 'a4', true)
        //     doc.addImage(imgData, 'PNG', 10, 10)
        //     doc.save('sample-file.pdf')
        //     console.log(imgData)
        // })
        // const canvas = document.createElement("canvas")
        // const ctx = canvas.getContext("2d")
        // Set width and height
        // let img = document.createElement("img")
        // img.src = image
        // img.crossOrigin = "anonymous"
        // img.width = 250
        // img.height = 250
        // canvas.width = img.width
        // canvas.height = img.height
        // ctx.drawImage( img, 0, 0 )
        // const imgCanvas = canvas.toDataURL()
        // console.log(imgCanvas)
        // html.append(img)
        // html.append(canvas)
        // doc.addImage(imgCanvas, "JPEG", 15, 40, 180, 180)
        // canvas.width = img.width
        // canvas.height = img.height
        // // Draw the image
        // console.log(img)
        // doc.text(media[0].city.name, 35, 25)
        // doc.save("thisMotion.pdf");
        window.print()

    }

    render() {
        return (
            <div id="pinter">
                <Dimmer active={this.props.media.loading} page>
                    <Loader indeterminate>Cargando</Loader>
                </Dimmer>
                <br />
                <Grid columns={1} centered>
                    <Grid.Column textAlign="center">
                        <Image src={logo} size="small" centered />
                    </Grid.Column>
                    {/* <Grid.Column textAlign="center">
                        <Header as="h3">Descargar medio</Header>
                    </Grid.Column> */}
                    <GridColumn textAlign="center">
                        <Label basic color='orange' className="no-print" size="large">
                            Para descargar en PDF presiona el bóton imprimir y en el campo de destino selecciona la opción guardar en pdf
                        </Label>
                    </GridColumn>
                    <Grid.Column textAlign="center" className="no-print">
                        <Button
                            content="Descargar"
                            icon="print"
                            labelPosition="right"
                            color="orange"
                            onClick={this.printPage}
                        />
                    </Grid.Column>
                </Grid>
                {this.renderMediaDescription()}
                <br/>
                <br/>
                <Grid columns={1} centered>
                    <Grid.Column>
                        <Header as="h5" textAlign="center">
                            CONTACTANOS: <b>4433382109</b>
                        </Header>
                        <List
                            verticalAlign="top"
                            style={{ textAlign: "center" }}>
                            <List.Item>
                                <List.Content>
                                    <List.Header
                                        href={`tel:+52${this.props.media.currentExecutive.phone}`}
                                        as="strong">
                                        {/* {`${this.props.media.currentExecutive.name}`} */}
                                    </List.Header>
                                    <List.Description>
                                        <p>
                                            {/* <strong>{this.props.media.currentExecutive.phoneFormatted}</strong><br/>
                                            <strong>Oficina: {this.props.media.currentExecutive.officePhoneNumberFormatted}</strong><br/><br/> */}
                                            {/* <strong>{this.props.media.currentExecutive.email}</strong><br/><br/> */}
                                        </p>
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <GridColumn textAlign="center" className="no-print">
                        <Button
                            content="Descargar"
                            icon="print"
                            labelPosition="right"
                            color="orange"
                            onClick={this.printPage}
                        />
                    </GridColumn>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => state;

export default connect(mapStateToProps)(
    GoogleApiWrapper({
        apiKey: DEFAULT.MAPS_API_KEY,
        libraries: ["places"],
    })(MediaDetails)
)
