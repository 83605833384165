import { saveFavoritesOnLocal } from "../utils/common"
import initialState from "./initialState"

import { toast } from "react-toastify"

const notifyError = message =>
    toast.error(message, { position: toast.POSITION.BOTTOM_CENTER })

const notifyWarning = message =>
    toast.warn(message, { position: toast.POSITION.BOTTOM_CENTER })

const notifyInfo = message =>
    toast.info(message, { position: toast.POSITION.BOTTOM_CENTER })

const notifySuccess = message =>
    toast.success(message, { position: toast.POSITION.BOTTOM_CENTER })

export default function mediaReducer(state = initialState.media, action) {

    switch (action.type) {
        /* Add filters types to the state array */

        case "HANDLE_SET_FILTERS": {
            return {
                ...state,
                filters: action.payload,
            }
        }

        /* Add media to the state array */

        case "HANDLE_SET_MEDIA": {
            return {
                ...state,
                mediaList: action.payload,
                mediaFiltered: action.payload,
            }
        }

        case "HANDLE_SET_MAP": {
            return {
                ...state,
                map: action.payload,
            }
        }

        case "HANDLE_SET_MARKERS": {
            return {
                ...state,
                markersList: action.payload,
            }
        }

        case "HANDLE_SET_LOADING": {
            return {
                ...state,
                loading: action.payload,
            }
        }

        case "HANDLE_SET_MAP_CIRCLE": {
            return {
                ...state,
                mapCircleList: [...state.mapCircleList, action.payload],
            }
        }

        case "HANDLE_SET_MARKER_CLUSTERER": {
            return {
                ...state,
                markerClusterer: action.payload,
            }
        }

        case "HANDLE_SET_FAVORITE_MEDIA": {
            const exists = state.favoriteMediaList.find(
                medium => medium.id === action.newItem.id
            )
            
            if (!exists) {
                notifySuccess("Se ha agregado correctamente al carrito.")
                //agregar el medio al storage
                saveFavoritesOnLocal(action.newItem);
                return {
                    ...state,
                    favoriteMediaList: [
                        ...state.favoriteMediaList,
                        action.newItem,
                    ],
                }
            } else {
                notifyWarning("El medio seleccionado ya se encuentra en el carrito.")
                return state
            }
        }

        case "HANDLE_SET_POINTS_OF_INTEREST": {
            return {
                ...state,
                pointsOfInterest: {
                    ...state.pointsOfInterest,
                    [action.index]: action.payload,
                },
            }
        }

        case "HANDLE_REMOVE_POINTS_OF_INTEREST": {
            delete state.pointsOfInterest[action.removeItem]
            return {
                ...state,
            }
        }

        case "HANDLE_REMOVE_FAVORITE_MEDIA": {
            return {
                ...state,
                favoriteMediaList: state.favoriteMediaList.filter(
                    medium => medium.id !== action.removeItem.id
                ),
            }
        }

        case "HANDLE_FILTER_MEDIA": {
            const removeAccents = str => {
                return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            }
            let mediaFiltered = state.mediaList
            for (const filterType in action.filter) {
                mediaFiltered = mediaFiltered.filter(medium => {
                    if (action.filter[filterType].length > 0)
                        for (const key of action.filter[filterType]) {
                            if (
                                removeAccents(
                                    medium[filterType].name
                                ).toLowerCase() === key.toLowerCase()
                            )
                                return medium
                        }
                    else return mediaFiltered
                    return mediaFiltered
                })
            }
            return {
                ...state,
                mediaFiltered: mediaFiltered,
            }
        }

        case "HANDLE_SHOW_MEDIA_MODAL": {
            return {
                ...state,
                showMediaModal: action.show,
            }
        }

        case "HANDLE_SHOW_CONTACT_MODAL": {
            return {
                ...state,
                showContactModal: action.show,
            }
        }

        case "HANDLE_SET_CURRENT_MEDIUM": {
            return {
                ...state,
                currentMedium: action.medium,
            }
        }

        case "HANDLE_RANDOMIZE_EXECUTIVE": {
            return {
                ...state,
                currentExecutive:
                    state.executives[
                        Math.floor(Math.random() * state.executives.length)
                    ],
            }
        }

        case "HANDLE_SET_COUNTRY": {
            return {
                ...state,
                country: action.country,
            }
        }

        case "HANDLE_SET_VIEW_ONLY_FAVORITE_LIST": {
            return {
                ...state,
                viewOnlyFavoriteList: action.viewOnlyFavoriteList,
            }
        }
        

        default:
            return state
    }
}
