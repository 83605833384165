import localForage from "localforage"

export const hasItemExpired = async (key) => {
    const now = (new Date()).getTime()
    const data = await localForage.getItem(key)
    const created_at = data ? data.created_at : now - 1000000000000000
    return Math.abs(now - created_at) > 10800000 ? true : false
}

export const saveToLocalStorage = (key, data) => {
    const now = (new Date()).getTime()
    localForage.setItem(key, {data: data, created_at: now})
}

export const getFromLocalStorage = async (key) => {
    return await localForage.getItem(key).then(data => data)
}

export const saveFavoritesOnLocal = (medium) => {
    const stored = JSON.parse(localStorage.getItem('favorites')) || [];
    const mediums = [...stored,medium];
    localStorage.removeItem('favorites');
    localStorage.setItem('favorites', JSON.stringify(mediums));
}


export const deleteFavoritesOnLocal = (medium) => {
    const stored = JSON.parse(localStorage.getItem('favorites')) || [];
    const result = stored.filter((sto) => sto.id !== medium.id);
    localStorage.removeItem('favorites');
    localStorage.setItem('favorites', JSON.stringify(result));
}

export const getFavoritesFromLocal = () => {
    return JSON.parse(localStorage.getItem('favorites')) || false;
}
