import { DEFAULT } from '../config'
import { getFromLocalStorage, hasItemExpired, saveToLocalStorage } from '../utils/common'
import { jwtDecode } from "jwt-decode"



export const handleFetchFilters = () => {
    
    return async dispatch => {
        
        return await DEFAULT.axios.get(`${DEFAULT.api}/api/filters`)
            .then( res => {
                const data = res.data.data
                dispatch(handleSetFilters(data))
            })
    }
}

export const handleSetFilters = (filters) => {
    const filtersNotAllowed = [
        "Backlight",
        "Raqueta",
        "Vinil",
        "Pendones",
        "Espacio de activación",
        "Póster",
        "Poster",
        "Aire acondicionado",
        "Bastidor Espectacular",
        "Pasarela",
        "Letrero en fachada",
    ]
    const uniqueTypes = new Set();
    const filteredTypes = [];
    
    filteredTypes.push({ name: "Espectacular" });
    uniqueTypes.add("Espectacular");
    
    filters.type.forEach(type => {
        if (!filtersNotAllowed.includes(type.name) && !uniqueTypes.has(type.name)) {
            filteredTypes.push(type);
            uniqueTypes.add(type.name);
        }
    });
    
    filters.type = filteredTypes;
    return {
        type: "HANDLE_SET_FILTERS",
        payload: filters
    }
}

export const handleFetchMedia = (country) => {
    return async dispatch => {
        dispatch(handleSetLoading(true))
        const mediaIsExpired = await hasItemExpired(`media_${country}`)
        // const mediaIsExpired = true
        let media = []
        // if(mediaIsExpired) {
        //     return await DEFAULT.axios.get(`${DEFAULT.api}/api/media/country/${country}`)
        //         .then( res => {
        //             const data = res.data.data
        //             console.log(data)
        //             media.concat(data)
        //             console.log(media)
        //             saveToLocalStorage(`media_${country}`, data)
        //             dispatch(handleSetMedia(data))
        //             dispatch(handleSetLoading(false))
        //         })
        // } 
        if(mediaIsExpired) {
            let currentPage = 1
            let lastPage = 1 // Inicialmente asume que hay al menos una página

            while (currentPage <= lastPage) {
                try {
                    const res = await DEFAULT.axios.get(`${DEFAULT.api}/api/media/country/${country}?page=${currentPage}`)

                    const data = res.data.data
                    media = media.concat(data)
                    dispatch(handleSetLoading(false))
                    dispatch(handleSetMedia([...media]))
                    const pagination = res.data.pagination
                    currentPage = pagination.current_page + 1
                    lastPage = pagination.last_page // Actualiza lastPage según la respuesta actual

                } catch (error) {
                    console.error("Error fetching media:", error)
                    dispatch(handleSetLoading(false))
                    return
                }
            }
            
            saveToLocalStorage(`media_${country}`, media)
            dispatch(handleSetMedia(media))
        } else {
            const media = await getFromLocalStorage(`media_${country}`)
            if (media)
                dispatch(handleSetMedia(media.data))
        }
        dispatch(handleSetLoading(false))
    }
}

export const handleSetMap = (map) => {
    return {
        type: "HANDLE_SET_MAP",
        payload: map
    }
}

export const handleSetLoading = (loading) => {
    return {
        type: "HANDLE_SET_LOADING",
        payload: loading
    }
}

export const handleSetMedia = (media) => {
    console.log(media)
    return {
        type: "HANDLE_SET_MEDIA",
        payload: media
    }
}

export const handleSetMarkers = (markers) => {
    return {
        type: "HANDLE_SET_MARKERS",
        payload: markers
    }
}

export const handleSetMapCircle = (circle) => {
    return {
        type: "HANDLE_SET_MAP_CIRCLE",
        payload: circle
    }
}

export const handleSetPointsOfInterest = (pointsOfInterest, index) => {
    return {
        type: "HANDLE_SET_POINTS_OF_INTEREST",
        index: index,
        payload: pointsOfInterest
    }
}

export const handleRemovePointOfInterest = (index) => {
    return {
        type: "HANDLE_REMOVE_POINTS_OF_INTEREST",
        removeItem: index
    }
}

export const handleSetMarkerClusterer = (markerClusterer) => {
    return {
        type: "HANDLE_SET_MARKER_CLUSTERER",
        payload: markerClusterer
    }
}

export const handleSetFavoriteMedium = (medium) => {
    return {
        type: "HANDLE_SET_FAVORITE_MEDIA",
        newItem: medium
    }
}

export const handleRemoveFavoriteMedium = (medium) => {
    return {
        type: "HANDLE_REMOVE_FAVORITE_MEDIA",
        removeItem: medium
    }
}

export const handleFilterMedia = (filter) => {
    return {
        type: "HANDLE_FILTER_MEDIA",
        filter: filter
    }
}

export const handleShowMediaModal = (show) => {
    return {
        type: "HANDLE_SHOW_MEDIA_MODAL",
        show: show
    }
}

export const handleShowContactModal = (show) => {
    return {
        type: "HANDLE_SHOW_CONTACT_MODAL",
        show: show
    }
}

export const handleSetCurrentMedium = (medium) => {
    return async (dispatch) => {
        try {
            if (medium){
                const { id } = medium;
                const response = await DEFAULT.axios.get(`${DEFAULT.api}/api/media/show/${id}`);
                const fullMedium = response.data.data;
    
                dispatch({
                    type: "HANDLE_SET_CURRENT_MEDIUM",
                    medium: fullMedium,
                });
            } else {
                dispatch({
                    type: "HANDLE_SET_CURRENT_MEDIUM",
                    medium: null,
                });
            }
        } catch (error) {
            console.error("Error fetching medium:", error);
            dispatch({
                type: "HANDLE_SET_CURRENT_MEDIUM",
                medium: null,
            });
        }
    };
}

export const handleRandomizeExecutive = () => {
    return {
        type: "HANDLE_RANDOMIZE_EXECUTIVE",
    }
}

export const handleSetCountry = (country) => {
    return {
        type: "HANDLE_SET_COUNTRY",
        country: country
    }
}

export const handleSetViewOnlyFavoriteList= (viewOnlyFavoriteList) => {
    return {
        type: "HANDLE_SET_VIEW_ONLY_FAVORITE_LIST",
        viewOnlyFavoriteList: viewOnlyFavoriteList
    }
}

export const handleFetchMedium = (code) => {
    return async () => {
        return await DEFAULT.axios.get(`${DEFAULT.api}/api/media/${code}`)
            .then( res => {
                const data = res.data.data
                return data
            })
    }
}

export const handleFetchMediaByCodes = (codes) => {
    return async (dispatch) => {
        try {
            console.log(codes);
            const media = await Promise.all(
                codes.map(async code => 
                    (await DEFAULT.axios.get(`${DEFAULT.api}/api/media/${code}`)).data.data
                )
            );
            console.log(media);
            dispatch(handleSetMedia(media));
        } catch (error) {
            console.error("Error fetching media:", error);
            dispatch(handleSetMedia([]));
        }
    };
};

