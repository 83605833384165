export const countries = {
    'MX': 'México',
    'CO': 'Colombia',
    'PA': 'Panama',
    'PE': 'Perú',
    'CL': 'Chile',
    'GT': 'Guatemala',
    'NI': 'Nicaragua',
    'CR': 'Costa Rica',
    'BO': 'Bolivia',
    'HN': 'Honduras',
    'PY': 'Paraguay'
}