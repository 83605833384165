const Colors = {
    'espectacular': '#12386e',
    'azotea': '#12386e',
    'piso': '#12386e',
    'unipolar': '#12386e',
    'muro': '#1c588c',
    'valla': '#d9296a',
    'pantalla': '#594e86',
    'landmark': '#730d02',
    'parabus': '#9801ff',
    // 'anuncio': '#730e03',
    'puente peatonal': '#73503c',
    'barda publicitaria': '#a10573',
    'valla digital': '#d9296a',
    primary: "#FE7F31",
    primaryOp: "rgba(254, 127, 49, .85)",
    stylePrimary: {background: "#FE7F31", color: "white"},
    secondary: "#383838",
    styleSecondary: {background: "#383838", color: "white"}
}

export default Colors