export default {
    "San José": [
        "Central",
        "Escazú",
        "Desamparados",
        "Puriscal",
        "Tarrazú",
        "Aserrí",
        "Mora",
        "Goicoechea",
        "Santa Ana",
        "Alajuelita",
        "Vázquez De Coronado",
        "Acosta",
        "Tibás",
        "Moravia",
        "Montes De Oca",
        "Turrubares",
        "Dota",
        "Curridabat",
        "Pérez Zeledón",
        "León Cortés Castro"
    ],
    "Alajuela": [
        "Central",
        "San Ramón",
        "Grecia",
        "San Mateo",
        "Atenas",
        "Naranjo",
        "Palmares",
        "Poás",
        "Orotina",
        "San Carlos",
        "Zarcero",
        "Sarchí",
        "Upala",
        "Los Chiles",
        "Guatuso",
        "Río Cuarto"
    ],
    "Cartago": [
        "Central",
        "Paraíso",
        "La Unión",
        "Jiménez",
        "Turrialba",
        "Alvarado",
        "Oreamuno",
        "El Guarco"
    ],
    "Heredia": [
        "Central",
        "Barva",
        "Santo Domingo",
        "Santa Barbara",
        "San Rafael",
        "San Isidro",
        "Belén",
        "Flores",
        "San Pablo",
        "Sarapiquí"
    ],
    "Guanacaste": [
        "Liberia",
        "Nicoya",
        "Santa Cruz",
        "Bagaces",
        "Carrillo",
        "Cañas",
        "Abangares",
        "Tilarán",
        "Nandayure",
        "La Cruz",
        "Hojancha"
    ],
    "Puntarenas": [
        "Central",
        "Esparza",
        "Buenos Aires",
        "Montes De Oro",
        "Osa",
        "Quepos",
        "Golfito",
        "Coto Brus",
        "Parrita",
        "Corredores",
        "Garabito"
    ],
    "Limón": [
        "Central",
        "Pococí",
        "Siquirres",
        "Talamanca",
        "Matina",
        "Guácimo"
    ]
}