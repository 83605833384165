import axios from "axios"
// import cookie from "react-cookies"


const protocol = window.location.protocol
const client = axios.create({ headers: { 'Content-Type': 'application/json'}  })

// client.interceptors.request.use(function (config) {
//     config.headers.Authorization = cookie.load('token') ? `Token ${cookie.load('token')}` : ''
//     return config;
// })

// client.interceptors.response.use(
//     response => response,
//     error => {
//         if (error.response) {
//             const response = error.response

//             switch (response.status) {
//                 case 401:
//                     // TODO: Remove cache, session or cookies
//                     localStorage.clear();
//                     window.location.href = '/login'
//                     break
//                 case 400:
//                     return response.data
//                 default:
//                     // The request was made and the server responded with a status code
//                     // that falls out of the range of 2xx
//                     return error.response
//             }


//         } else if (error.request) {
//             console.log(error.request);
//             // The request was made but no response was received
//             // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
//             // http.ClientRequest in node.js
//             console.log(error.request)
//         } else {
//             // Something happened in setting up the request that triggered an Error
//             console.log('Error', error.message)
//         }
//         return error

// })

// production mode prod, stage, dev
let env = "prod"

let host = ""
let api = ""
let port = ""
let sistema = ""
let storage =""

switch (env) {
    case "dev":
        host = 'localhost'
        api = `${protocol}//localhost:8000`
        port = '8000'
        sistema = 'http://sistema.naranti.org'
        storage = "https://s3.us-east-1.amazonaws.com/sistema.naranti"
        break
    case "stage":
        host = 'api.naranti.mx'
        api = `${protocol}//sistema.naranti.org`
        port = ''
        sistema = 'http://sistema.naranti.org'
        storage = "https://s3.us-east-1.amazonaws.com/sistema.naranti"
        break
    case "prod":
        host = 'api.naranti.mx'
        api = `https://sistema.naranti.org`
        port = ''
        sistema = 'https://sistema.naranti.org'
        storage = "https://s3.us-east-1.amazonaws.com/sistema.naranti"
        break
    default:
        host = 'localhost'
        api = `${protocol}//localhost:8000`
        port = '8000'
        sistema = 'http://sistema.naranti.org'
        storage = "https://s3.us-east-1.amazonaws.com/sistema.naranti"
        break
}

export const DEFAULT = {
    protocol: `${protocol}//`,
    host: `${protocol}//${host}`,
    port: port,
    api: api,
    sistema,
    storage,
    axios: client,
    MAPS_API_KEY: 'AIzaSyAfqg4rsHpdm4C9YhJOXZ8lVvkPitfSlBE'
    // api: `${protocol}//${host}:${port}`,
    // api: `${protocol}//${api}`,
}