export default {
    "Nueva Segovia": [
        "Jalapa",
        "Murra",
        "El Jicaro",
        "San Fernando",
        "Mozonte",
        "Dipilto",
        "Macuelizo",
        "Santa Maria",
        "Ocotal",
        "Ciudad Antigua",
        "Quilali",
        "Wiwili De Nueva Segovia"
    ],
    "Jinotega": [
        "Wiwili De Jinotega",
        "El Cua",
        "San Jose De Bocay",
        "Santa Maria De Pantasma",
        "San Rafael De Norte",
        "San Sebastian De Yali",
        "La Concordia",
        "Jinotega"
    ],
    "Madriz": [
        "Somoto",
        "Totogalpa",
        "Telpaneca",
        "San Juan De Rio Coco",
        "Palacaguina",
        "Yalaguina",
        "San Lucas",
        "Las Sabanas",
        "San Jose De Cusmapa"
    ],
    "Esteli": [
        "Pueblo Nuevo",
        "Condega",
        "Esteli",
        "San Juan De Limay",
        "La Trinidad",
        "San Nicolas"
    ],
    "Chinandega": [
        "San Pedro Del Norte",
        "San Francisco Del Norte",
        "Cinco Pinos",
        "Santo Tomas Del Norte",
        "El Viejo",
        "Puerto Morazan",
        "Somotillo",
        "Villa Nueva",
        "Chinandega",
        "El Realejo",
        "Corinto",
        "Chichigalpa",
        "Posoltega"
    ],
    "Leon": [
        "Achuapa",
        "El Sauce",
        "Santa Rosa Del Pe�on",
        "El Jicaral",
        "Larreynaga",
        "Telica",
        "Quezalguaque",
        "Leon",
        "La Paz Centro",
        "Nagarote"
    ],
    "Matagalpa": [
        "Rancho Grande",
        "Rio Blanco",
        "El Tuma La Dalia",
        "San Isidro",
        "Sebaco",
        "Matagalpa",
        "San Ramon",
        "Matiguas",
        "Muy Muy",
        "Esquipulas",
        "San Dionisio",
        "Terrabona",
        "Ciudad Dario"
    ],
    "Boaco": [
        "San Jose De Los Remates",
        "Boaco",
        "Camoapa",
        "Santa Lucia",
        "Teustepe",
        "San Lorenzo"
    ],
    "Managua": [
        "San Franciso Libre",
        "Tipitapa",
        "Mateare",
        "Villa El Carmen - Villa Carlos",
        "Ciudad Sandino",
        "Managua",
        "Ticuantepe",
        "El Crucero",
        "San Rafael Del Sur"
    ],
    "Masaya": [
        "Nindiri",
        "Masaya",
        "Tisma",
        "La Concepcion",
        "Masatepe",
        "Nandasmo",
        "Catarina",
        "San Juan De Oriente",
        "Niquinihomo"
    ],
    "Chontales": [
        "Comalapa",
        "San Francisco De Cuapa",
        "Juigalpa",
        "La Libertad",
        "Santo Domingo",
        "Santo Tomas",
        "San Pedro De Lovago",
        "Acoyapa",
        "Villa Sandino",
        "El Coral"
    ],
    "Granada": [
        "Diria",
        "Diriomo",
        "Granada",
        "Nandaime"
    ],
    "Carazo": [
        "San Marcos",
        "Jinotepe",
        "Dolores",
        "Diriamba",
        "El Rosario",
        "La Paz De Carazo",
        "Santa Teresa",
        "La Conquista"
    ],
    "Rivas": [
        "Tola",
        "Belen",
        "Potosi",
        "Buenos Aires",
        "Moyogalpa",
        "Altagracia",
        "San Jorge",
        "Rivas",
        "San Juan Del Sur",
        "Cardenas"
    ],
    "Rio San Juan": [
        "Morrito",
        "El Almendro",
        "San Miguelito",
        "San Carlos",
        "El Castillo",
        "San Juan Del Norte"
    ],
    "Raan": [
        "Waspan",
        "Puerto Cabezas",
        "Rosita",
        "Bonanza",
        "Waslala",
        "Mulukuku",
        "Siuna",
        "Prinzapolka"
    ],
    "Raas": [
        "Paiwas",
        "La Cruz De Rio Grande",
        "Desembocadura Del Rio Grande",
        "Laguna De Perlas",
        "El Tortuguero",
        "El Rama",
        "El Ayote",
        "Muelle De Los Bueyes",
        "Kukra Hill",
        "Corn Island",
        "Blefields",
        "Nueva Guinea"
    ]
}