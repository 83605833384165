export default {
    "Atlantida": [
        "La ceiba",
        "El porvenir",
        "Esparta",
        "Jutiapa",
        "La masica",
        "San francisco",
        "Tela",
        "Arizona"
    ],
    "Colon": [
        "Trujillo",
        "Balfate",
        "Iriona",
        "Limon",
        "Saba",
        "Santa fe",
        "Santa rosa aguan",
        "Sonaguera",
        "Tocoa",
        "Bonito oriental"
    ],
    "Comayagua": [
        "Comayagua",
        "Ajuterique",
        "El rosario",
        "Esquias",
        "Humuya",
        "La libertad",
        "Lamani",
        "La trinidad",
        "Lejamani",
        "Meambar",
        "Minas de oro",
        "Ojos de agua",
        "San jeronimo",
        "San jose de comayagua",
        "San jose del potrero",
        "San luis",
        "San sebastian",
        "Siguatepeque",
        "Villa de san antonio",
        "Las lajas",
        "Taulabe"
    ],
    "Copan": [
        "Santa rosa de copan",
        "Cabañas",
        "Concepcion",
        "Copan ruinas",
        "Corquin",
        "Cucuyagua",
        "Dolores",
        "Dulce nombre",
        "El paraiso",
        "Florida",
        "La jigua",
        "La union",
        "Nueva arcadia",
        "San agustin",
        "San antonio",
        "San jeronimo",
        "San jose",
        "San juan de opoa",
        "San nicolas",
        "San pedro",
        "Santa rita",
        "Trinidad",
        "Veracruz"
    ],
    "Cortes": [
        "San pedro sula",
        "Choloma",
        "Omoa",
        "Pimienta",
        "Potrerillos",
        "Puerto cortes",
        "San antonio de cortes",
        "San francisco de yojoa",
        "San manuel",
        "Santa cruz de yojoa",
        "Villanueva",
        "La lima"
    ],
    "Choluteca": [
        "Choluteca",
        "Apacilagua",
        "Concepcion de maria",
        "Duyure",
        "El corpus",
        "El triunfo",
        "Marcovia",
        "Morolica",
        "Namasigue",
        "Orocuina",
        "Pespire",
        "San antonio de flores",
        "San isidro",
        "San jose",
        "San marcos de colon",
        "Santa ana de yusguare"
    ],
    "El paraiso": [
        "Yuscaran",
        "Alauca",
        "Danli",
        "El paraiso",
        "Guinope",
        "Jacaleapa",
        "Liure",
        "Moroceli",
        "Oropoli",
        "Potrerillos",
        "San antonio de flores",
        "San lucas",
        "San matias",
        "Soledad",
        "Teupasenti",
        "Texiguat",
        "Vado ancho",
        "Yauyupe",
        "Trojes"
    ],
    "Francisco morazan": [
        "Distrito central",
        "Alubaren",
        "Cedros",
        "Curaren",
        "El porvenir",
        "Guaimaca",
        "La libertad",
        "La venta",
        "Lepaterique",
        "Maraita",
        "Marale",
        "Nueva armenia",
        "Ojojona",
        "Orica",
        "Reitoca",
        "Sabanagrande",
        "San antonio de oriente",
        "San buenaventura",
        "San ignacio",
        "Cantarranas",
        "San miguelito",
        "Santa ana",
        "Santa lucia",
        "Talanga",
        "Tatumbla",
        "Valle de angeles",
        "Villa san francisco",
        "Vallecillos"
    ],
    "Gracias a dios": [
        "Puerto lempira",
        "Brus laguna",
        "Ahuas",
        "Juan francisco bulnes",
        "Villeda morales",
        "Wampusirpi"
    ],
    "Intibuca": [
        "La esperanza",
        "Camasca",
        "Colomoncagua",
        "Concepcion",
        "Dolores",
        "Intibuca",
        "Jesus de otoro",
        "Magdalena",
        "Masaguara",
        "San antonio",
        "San isidro",
        "San juan",
        "San marcos sierra",
        "San miguelito",
        "Santa lucia",
        "Yamaranguila",
        "San francisco de opalaca"
    ],
    "Islas de la bahia": [
        "",
        "",
        "",
        ""
    ],
    "La paz": [
        "La paz",
        "Aguanqueterique",
        "Cabañas",
        "Cane",
        "Chinacla",
        "Guajiquiro",
        "Lauterique",
        "Marcala",
        "Mercedes de oriente",
        "Opatoro",
        "San antonio del norte",
        "San jose",
        "San juan",
        "San pedro de tutule",
        "Santa ana",
        "Santa elena",
        "Santa maria",
        "Santiago de puringla",
        "Yarula"
    ],
    "Lempira": [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        ""
    ],
    "Ocotepeque": [
        "Ocotepeque",
        "Belen gualcho",
        "Concepcion",
        "Dolores merendon",
        "Fraternidad",
        "La encarnacion",
        "La labor",
        "Lucerna",
        "Mercedes",
        "San fernando",
        "San francisco del valle",
        "San jorge",
        "San marcos",
        "Santa fe",
        "Sensenti",
        "Sinuapa"
    ],
    "Olancho": [
        "Juticalpa",
        "Campamento",
        "Catacamas",
        "Concordia",
        "Dulce nombre de culmi",
        "El rosario",
        "Esquipulas del norte",
        "Gualaco",
        "Guarizama",
        "Guata",
        "Guayape",
        "Jano",
        "La union",
        "Mangulile",
        "Manto",
        "Salama",
        "San esteban",
        "San francisco de becerra",
        "San francisco de la paz",
        "Santa maria del real",
        "Silca",
        "Yocon",
        "Patuca"
    ],
    "Santa barbara": [
        "Santa barbara",
        "Arada",
        "Atima",
        "Azacualpa",
        "Ceguaca",
        "San jose de colinas",
        "Concepcion del norte",
        "Concepcion del sur",
        "Chinda",
        "El nispero",
        "Gualala",
        "Ilama",
        "Macuelizo",
        "Naranjito",
        "Nuevo celilac",
        "Petoa",
        "Proteccion",
        "Quimistan",
        "San francisco de ojuera",
        "San luis",
        "San marcos",
        "San nicolas",
        "San pedro de zacapa",
        "Santa rita",
        "San vicente centenario",
        "Trinidad",
        "Las vegas",
        "Nueva frontera"
    ],
    "Valle": [
        "Nacaome",
        "Alianza",
        "Amapala",
        "Aramecina",
        "Caridad",
        "Goascoran",
        "Langue",
        "San francisco de coray",
        "San lorenzo"
    ],
    "Yoro": [
        "Yoro",
        "Arenal",
        "El negrito",
        "El progreso",
        "Jocon",
        "Morazan",
        "Olanchito",
        "Santa rita",
        "Sulaco",
        "Victoria",
        "Yorito"
    ]
}