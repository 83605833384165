import camioneta from './markers/camioneta.svg'
import cartelera from './markers/cartelera.svg'
import cartelera_1 from './markers/cartelera_1.svg'
import poster from './markers/poster.svg'
import iconMaker from './markers/iconMarker2.png'
import mediaGroup from './markers/mediaGroup.png'
import markerClusterer from './markers/m1.png'
import iconMarkerBarda from './markers/Barda-01.png'
import iconMarkerUnipolar from './markers/Unipolar-01.png'
import iconMarkerPuente from './markers/Puente-01.png'
import iconMarkerMuro from './markers/Muro-01.png'
import iconMarkerValla from './markers/Valla-01.png'
import iconMarkerPantalla from './markers/Pantalla-01.png'
import iconMarkerLandmark from './markers/Landmark-01.png'
import iconMarkerParabus from './markers/Parabus-01.png'


const Markers = {
    camioneta,
    cartelera_1,
    cartelera,
    poster,
    mediaGroup,
    markerClusterer,
    iconMaker,
    'azotea': iconMarkerUnipolar,
    'piso': iconMarkerUnipolar,
    'unipolar': iconMarkerUnipolar,
    'muro': iconMarkerMuro,
    'valla': iconMarkerValla,
    'pantalla': iconMarkerPantalla,
    'landmark': iconMarkerLandmark,
    'parabus': iconMarkerParabus,
    'puente peatonal': iconMarkerPuente,
    'barda publicitaria': iconMarkerBarda,
    'valla digital': iconMarkerValla,
}

export default Markers