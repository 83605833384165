import logoMX from '../assets/mx/logo.png'
import logoCO from '../assets/co/logo.png'
import logoPA from '../assets/pa/logo.png'
import logoPE from '../assets/pe/logo.png'
import logoCL from '../assets/cl/logo.png'
import logoGT from '../assets/gt/logo.png'
import logoNI from '../assets/ni/logo.png'
import logoCR from '../assets/cr/logo.png'
import logoBO from '../assets/bo/logo.png'
import logoHN from '../assets/hn/logo.png'
import logoPY from '../assets/py/logo.png'


export const country_settings = {
    'MX' : {
        logo: logoMX
    },
    'CO' : {
        logo: logoCO
    },
    'PA' : {
        logo: logoPA
    },
    'PE' : {
        logo: logoPE
    },
    'CL' : {
        logo: logoCL
    },
    'GT' : {
        logo: logoGT
    },
    'NI' : {
        logo: logoNI
    },
    'CR' : {
        logo: logoCR
    },
    'BO' : {
        logo: logoBO
    },
    'HN' : {
        logo: logoHN
    },
    'PY' : {
        logo: logoPY
    }
}