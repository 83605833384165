import { createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers'
import thunk from "redux-thunk"
 
/*Create a function called configureStore */
const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
)
export default store
// export default function configureStore() {  
//   return createStore(rootReducer)
// }